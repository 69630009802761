<template>
  <div class="page">
    <video
        ref="video_player"
        class="video"
        loop
        autoplay
        playsinline
        muted
        preload="auto"
    >
      <source :src="require(`../../public/video/${videoName}`)">
    </video>
    <div class="page__info-block">
      <div class="page__head-block">
        <div class="logo-dark">
          <img src="~assets/img/logo-main.svg" alt="logo">
        </div>

      </div>
      <p class="page__title">
        {{ 'Полная коллекция правил, ресурсов и материалов\nдля создания дизайн-макетов' }}
      </p>
      <a href="../download-file/12-Video/We-are-Steor.mp4"
         target="_blank"
         class="home-btn">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="24" cy="24" r="16" stroke="white" stroke-linecap="round" stroke-dasharray="0.2 4"/>
          <path d="M31 24L20.5 30.0622L20.5 17.9378L31 24Z" fill="white"/>
        </svg>
        Brand video
      </a>
    </div>
  </div>
</template>
<script>
import {setTimeout} from "timers";

export default {
  computed: {
    videoName() {
      if (window.innerWidth <= 800) {
        return 'waves1920.mp4'
      } else if (window.innerWidth <= 1280) {
        return 'waves1920.mp4'
      }
      return 'waves2560.mp4'
    }
  },
  beforeMount() {
    setTimeout(() => {
      this.$refs.video_player.play();
    }, 300);
  },
  methods: {
    handle() {
      setTimeout(() => {
        this.$refs.video_player.play();
      }, 300);
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/style/var-style";

.video {
  z-index: 2;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: none;
}

.page {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background: #000032;

  &__background {
    position: absolute;
    min-height: calc(100vh + 10px);
    min-width: calc(100vw - 400px);
    width: 100%;
  }

  &__info-block {
    display: flex;
    flex-direction: column;
    z-index: 2;
    padding-left: 7.8px;
    //position: absolute;
    max-width: 760px;
    width: 100%;
    //top: 0;
    //left: 136px;
    height: 100%;
    padding-top: 266px;
    margin-left: 136px;
  }

  &__head-block {
    max-width: 384px;
    margin-bottom: 220px;
  }

  &__big-text {
    color: #fff;
    font-weight: 500;
    font-size: 31px;
    line-height: 38px;
    margin-bottom: 10px;
    letter-spacing: -0.3px;

    .page__color-second {
      color: #E6175C;
    }
  }

  &__title {
    font-family: Stapel, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 48px;
    line-height: 110%;
    letter-spacing: 0.03em;
    margin-bottom: 66px;
    color: #FFFFFF;
    white-space: pre-line;
  }
}

@media screen and (min-width: 1456px) {
  picture {
    position: fixed;
    bottom: -4px;
    left: 255px;
    z-index: 1;

    img {
      position: absolute;
      bottom: -20vh;
      left: 0;
      width: 120vw;
      min-height: 120vh;
    }
  }
}

@media screen and (max-width: 1456px) {
  picture {
    position: fixed;
    bottom: -4px;
    left: 0;
    z-index: 1;

    img {
      width: 100vw;
      min-height: 100vh;
    }
  }
}

.home-btn {
  font-family: Suisse Intl, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #FFFFFF;
  padding: 8px;
  background: #0759EA;
  border-radius: 4px;
  width: 200px;
  height: 64px;
  margin-left: 1px;
  border: none;
  text-decoration: none;

  svg {
    margin-right: 12px;
  }

  &:hover {
    background: #0652D8;
    box-shadow: 0 20px 40px -8px rgba(5, 71, 186, 0.4);
  }

  &:active {
    background: #064ECC;
    box-shadow: 0px -2px 8px rgba(253, 255, 255, 0.9), 0px 4px 24px rgba(187, 195, 206, 0.8);
  }
}

.logo-dark {
  height: 96px;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
  }

  &__text {
    font-size: 50px;
    line-height: 50px;
    padding-left: 17px;
    text-transform: uppercase;
    position: relative;
    top: 1px;
  }
}

@media screen and (max-width: 1600px) {
  .page__head-block {
    margin-bottom: 160px;
  }
  .video {
    width: 1660px;
  }
}

@media screen and (max-width: 1456px) {
  .page__background {
    left: -128px;
    min-width: 100vw;
  }
  .video {
    width: 1660px;
  }
}

@media screen and (max-width: 1360px) {
  .page {
    width: 100vw;

    &__info-block {
      padding: 0;
    }
  }
}

@media screen and (max-width: 1000px) {
  .container-full {
    padding: 0 10px 0;
  }
  .page__background {
    display: block;
    width: 100%;
    min-width: 100%;
    left: 0;
  }
  .page__info-block {
    padding: 0;
    margin: 0 auto;
    left: auto;
    right: auto;
    top: 10vh;
  }
  .video {
  }
}

@media screen and (max-width: 768px) {
  .page__big-text {
    font-size: 16px;
    line-height: 24px;
  }
  .page__info-block {
    height: auto;
  }
  .logo-dark__text {
    font-size: 30px;
  }
}

@media screen and (max-width: 700px) {
  .page__title {
    font-size: 32px;
  }
  .page__info-block {
    max-width: 320px;
    top: 25vh;
  }
  .page__head-block {
    margin-bottom: 100px;
  }
  picture img {
    width: auto;
    min-height: 100vh;
  }
  .video {
    min-height: 62vh;
  }
}

@media screen and (max-width: 600px) {
  .video {
    min-height: 56vh;
  }
  .page__title {
    font-size: 24px;
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 400px) {
  .video {
    min-height: 56vh;
  }
}

@media screen and (max-height: 540px) {
  .page {
    &__info-block {
      padding-top: 60px;
    }

    &__head-block {
      margin-bottom: 25px;
    }

    &__title {
      margin-bottom: 25px;
    }
  }
}

@media screen and (max-height: 420px) {
  .page {
    &__info-block {
      padding-top: 40px;
    }

    &__head-block {
      margin-bottom: 15px;
    }

    &__title {
      margin-bottom: 15px;
    }
  }
}

@media screen and (max-height: 900px) and (min-width: 1360px) {
  .page {
    width: 100vw;

    &__info-block {
      padding: 0;
    }
  }
}

@media screen and (max-height: 900px) and (min-width: 1360px) {
  .page {
    width: 100vw;

    &__info-block {
      padding: 0;
    }
  }
}

@media screen and (max-height: 610px) and (min-width: 1360px) {
 .page {
   &__head-block {
     margin-bottom: 100px;
   }
 }
}
</style>
